import SUPER_ADMIN_DATA from '@/src/router/views/super-admin/super-admin.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (statData) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: SUPER_ADMIN_DATA.text,
          value: statData?.total_super_admins || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: SUPER_ADMIN_DATA.TOOLTIP_DESCRIPTION,
        },
      ],
    },
  ]
  return stats
}

export const superAdminGraphChart = (statData) => {
  let PIE_GRAPH_VALUE = [
    statData?.total_super_admins - statData?.login_enabled_count,
    statData?.login_enabled_count,
  ]
  const chartData = [
    GRAPH_CONSTANTS.PIE_CHART(
      SUPER_ADMIN_DATA.PIE_LABELS,
      PIE_GRAPH_VALUE,
      SUPER_ADMIN_DATA.PIE_BACKGROUND_COLOR,
      SUPER_ADMIN_DATA.PIE_TITLE,
    ),
  ]
  return chartData
}
