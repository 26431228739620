<template>
  <TitleWrapper
    :filter-option="true"
    title="SUPER_ADMIN"
    tooltip-title="SUPER_ADMIN"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
    @cancel="cancelHanlder"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiInput
          v-model.trim="filtersPayload.first_name"
          :class="$style.widthItems"
          class="flex-1"
          title="First Name"
          type="text"
          placeholder="SEARCH_BY_FNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.FIRST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.last_name"
          :class="$style.widthItems"
          class="flex-1"
          title="Last Name"
          type="text"
          placeholder="SEARCH_BY_LNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.LAST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.phone"
          title="Phone Number"
          placeholder="Phone Number"
          class="flex-1"
          rules="numeric"
          :filter="FILTERS_LIST.PHONE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.email"
          class="flex-1"
          title="Email"
          placeholder="Email"
          :filter="FILTERS_LIST.EMAIL"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_after"
          title="DOB_GT"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_GREATER_THAN"
          @change="birthDateAfter"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_before"
          title="DOB_LT"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_LESS_THAN"
          @change="birthDateBefore"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.login_enabled"
          title="STATUS"
          :options="LOGIN_ENABLED_STATUSES"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.LOGIN_ENABLED_STATUS"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import GENERAL_CONSTANT, { USER_CONSTANTS } from '@src/constants/general-constants'
import { mapActions, mapState } from 'vuex'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import filterMixins from '@/src/mixins/filter-mixins.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    TitleWrapper,
    UiDatePicker,
    UiSingleSelect,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTERS_LIST,
      LOGIN_ENABLED_STATUSES: USER_CONSTANTS.LOGIN_ENABLED_STATUSES,
      filtersCount: GENERAL_CONSTANT.EMPTY_RECORD,
      filtersPayload: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        date_of_birth_before: '',
        date_of_birth_after: '',
        login_enabled: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      campus: (state) => state.layout.currentCampusScope || null,
    }),
  },

  watch: {
    campus: {
      handler() {
        this.clearFilters()
      },
    },
    currentSectionScope: {
      handler() {
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
  },
  methods: {
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
