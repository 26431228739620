<template>
  <div
    class="tooltip z-20 bg-white text-text-color absolute rounded-md min-w-1.5 w-fit top-10"
    :class="[`tooltip__${direction}`]"
  >
    <div class="flex p-2.5 gap-x-2 text-base">
      <!-- image section -->
      <div v-if="!image" class="flip-tooltip self-start pt-1 sm:pt-0">
        <icon class="icon" :icon="icon" />
      </div>
      <div v-else class="w-7.5 h-7.5">
        <div v-if="user.image">
          <img
            :src="user.image"
            class="h-full w-full rounded-full border border-border-pastel-green min-h-0.3 max-h-0.3"
          />
        </div>
        <span
          v-else
          class="h-full w-full rounded-full border border-border-pastel-green flex justify-center items-center text-sm bg-primary-purple-50 text-primary-purple-600"
        >
          {{ initials(getLabel(user)) }}
        </span>
      </div>
      <!-- content section -->
      <div>
        <div class="text-xs font-medium text-text-color capitalize">
          {{ getLabel(user) }}
        </div>
        <div class="text-xs text-primary-grey-light">
          {{ getDetail(user) }}
        </div>
        <div
          v-for="(item, key, index) in user.details"
          :key="index"
          class="text-xs text-menu pb-0.5 font-normal"
        >
          <div>
            <span class="capitalize pr-1">{{ key }}:</span>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import generalUtil from '@src/mixins/general-mixins.js'
import icon from '@components/icons/icon.vue'

export default {
  components: { icon },
  mixins: [generalUtil],
  props: {
    icon: {
      type: String,
      default: 'infoCircle',
    },
    image: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    direction: { type: String, default: 'left' },
    nestedDetail: {
      type: Array,
      default: () => [],
    },
    label: { type: [String, Number], default: 'title' },
    detail: { type: [String, Number], default: '' },
  },
  methods: {
    /**
     * Get Label
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getLabel(option) {
      if (typeof option === 'object') {
        if (this.nestedLabel) {
          let nested = option
          this.nestedLabel.forEach((op) => {
            if (nested) nested = nested[op]
          })
          return nested
        } else return option[this.label]
      }
      return option
    },
    /**
     * Get Detail
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getDetail(option) {
      if (typeof option === 'object') {
        if (this.nestedDetai) {
          let nested = option

          this.nestedDetail.forEach((op) => {
            nested = nested[op]
          })
          return nested
        } else return option[this.detail]
      }
      return option
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  box-shadow: 1px 1px 15px var(--very-dark-blue);
  transition: opacity 0.3s;
  &::before {
    position: absolute;
    top: -5px;
    width: 10px;
    height: 10px;
    content: '';
    background: var(--bg-white);
    opacity: 1;
    transform: rotate(45deg);
  }
  &__left {
    &::before {
      left: 12px;
    }
  }
  &__right {
    &::before {
      right: 20px;
    }
  }
}
</style>
