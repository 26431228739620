<template>
  <div>
    <Field v-show="hidden" v-model="dateValue" :rules="rules" :name="name === '' ? title : name" />
    <DatePicker
      ref="datepicker"
      v-model="currentDate"
      title="Date"
      :picker="picker"
      :options="options"
      :disabled="disabled"
      :actions-attr="calenderActionAttributes"
      class="flex-1 w-full relative"
      :actions="calenderAction || []"
      :main-container-attributes="mainContainerAttributes"
      @update:modelValue="applyNewDateFilter"
    >
      <div>
        <div v-if="!hideTitle" class="text-gray-700 text-sm font-medium font-inter flex mb-1.5">
          <span v-i18n="translationTitle" class="capitalize">{{ title }}</span>
          <span v-if="rules.includes('required')" class="text-text-color-danger pl-1">*</span>
        </div>
        <div
          :class="[
            errorBorderStyle,
            dateTimeBorderStyle,
            inputColor,
            isErrorBgColor,
            { 'cursor-not-allowed bg-disable-color': disabled },
          ]"
          class="h-11 bg-white w-full y justify-between px-3 py-2 flex items-center rounded-lg cursor-pointer"
        >
          <button
            type="text"
            class="h-full w-full flex justify-between items-center md:text-sm focus:outline-none gap-2"
            :class="{ 'cursor-not-allowed': disabled }"
          >
            <div :class="labelStyle">{{ Label }}</div>
            <div>
              <icon class="icon" icon="calender" color="primary-green" height="16" width="16" />
            </div>
          </button>
        </div>
      </div>
    </DatePicker>
    <div v-if="!noError" ref="errorContainer" :class="[errorClasses]">
      <ErrorMessage v-slot="{ message }" :name="name === '' ? title : name">
        <small class="error-msg text-text-color-danger text-sm italic -mt-0">
          <p>{{ displayError(message) }}</p>
        </small>
      </ErrorMessage>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/src/lib/j-date-picker/QDatePicker.vue'
import icon from '@components/icons/icon.vue'
import { translatedError } from '@src/utils/generalUtil.js'
import {
  formatDateFromLocalToUTC,
  getStartTimeOfTheDay,
  getEndTimeOfTheDay,
  formatDate,
} from '@utils/moment.util'
import { DATE_MODE } from '@src/constants/date-time-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { mapGetters } from 'vuex'
import validationMixin from '@src/mixins/components/validation-mixin.js'
import { Field, ErrorMessage, configure } from 'vee-validate'
import { validationConfiguration } from '@src/vee-validate/index.js'
configure(validationConfiguration)

export default {
  components: {
    DatePicker,
    Field,
    ErrorMessage,
    icon,
  },
  mixins: [validationMixin],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Date, Object],
      default: '',
    },
    title: {
      type: String,
      default: 'date',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    picker: {
      type: String,
      default: 'date',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDateTime: {
      type: Boolean,
      default: false,
    },
    inputColor: { type: String, default: '' },
    filter: {
      type: Object,
      default: () => {},
    },
    isErrorBgColor: {
      type: String,
      default: '',
    },
    dateMode: {
      type: String,
      default: 'date',
    },
    noError: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change', 'update:modelValue', 'emitQuery'],
  data() {
    return {
      translationTitle: 'title',
      currentDate: new Date(),
      hidden: false,
      dateValue: '',
      isErrorAvailable: false,
      defaultLabel: this.$t(`dashboard.Select Date`),
      calenderActionAttributes: {
        style: {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '12px',
        },
        class: 'pt-4 mt-[18px] border-t border-primary-grey-200 rtl:flex-row-reverse font-inter',
      },
      mainContainerAttributes: {
        left: '144px',
      },
      options: {
        saveLastState: false,
      },
      calenderAction: [
        {
          text: this.$t(`dateTime.Cancel`),
          handler: 'cancel',
          attr: {
            class:
              ' w-[8.875rem] h-[2.5rem] border border-primary-gray-300 rounded-lg text-sm font-semibold text-primary-gray-700 shadow-button',
          },
        },
        {
          text: this.$t(`dateTime.Apply`),
          handler: 'apply',
          attr: {
            class:
              ' w-[8.875rem] h-[2.5rem] text-sm text-white bg-primary-purple-600 font-semibold rounded-lg shadow-button',
          },
        },
      ],
    }
  },
  computed: {
    dateTimeBorderStyle() {
      return this.isDateTime ? '' : 'border'
    },
    errorBorderStyle() {
      return this.isErrorAvailable ? 'error-border' : ''
    },
    ...mapGetters('layout', ['campusTimeZone']),
    /**
     * Create label for DatePicker
     * @returns  Date Label
     */

    errorClasses() {
      return !this.isDateTime ? 'flex justify-end h-5' : 'absolute right-1'
    },
    Label() {
      if (this.modelValue)
        return this.IsYearPicker || !this.modelValue.includes('T')
          ? this.modelValue
          : formatDate(this.modelValue)
      // return this.IsYearPicker ? this.modelValue : formatDate(this.modelValue)
      else return this.defaultLabel
    },
    labelStyle() {
      return this.modelValue
        ? 'lg:text-base text-sm text-primary-gray-700'
        : 'text-gray-500 opacity-50 text-sm md:text-base'
    },
    IsYearPicker() {
      return this.picker === 'year'
    },
  },
  watch: {
    /**
     * Set new modelValue to currentDate
     * @retuns current date to the parent component
     */
    modelValue: {
      handler(val) {
        this.dateValue = val
        this.errorHandler()
        let UTCDate = this.dateMode === 'date' ? this.modelValue : formatDate(this.modelValue)
        const date = this.emitDate(UTCDate)
        this.$emit('change', date)
        this.$emit('update:modelValue', date)
      },
    },
  },
  /**
   * In created hook emit the current date to the parent components
   */
  created() {
    if (this.modelValue) {
      this.dateValue = this.modelValue
      let UTCDate = this.dateMode === DATE_MODE.DATE ? this.dateValue : formatDate(this.dateValue)
      const date = this.emitDate(UTCDate)
      this.$emit('update:modelValue', date)
    }

    if (this.modelValue && this.filter && this.filter.option)
      this.$emit(
        'emitQuery',
        buildWhereQuery(
          this.filter.option,
          this.filter.key,
          this.IsYearPicker || this.dateMode === DATE_MODE.DATE
            ? formatDate(this.modelValue)
            : this.modelValue,
        ),
      )
  },
  methods: {
    translatedError,
    /**
     * Apply new selected filter on date to show specific date's data
     * @param {date} modelValue- Selected Date
     * emit the date to parent component
     */
    applyNewDateFilter(modelValue) {
      const date = formatDateFromLocalToUTC(modelValue)
      let emittedData = this.emitDate(date)
      if (modelValue && this.filter && this.filter.option)
        this.$emit(
          'emitQuery',
          buildWhereQuery(
            this.filter.option,
            this.filter.key,
            this.IsYearPicker ? modelValue : emittedData,
          ),
        )
      this.$emit('update:modelValue', this.IsYearPicker ? modelValue : emittedData)
    },
    emitDate(date) {
      let mode = this.dateMode
      switch (mode) {
        case DATE_MODE.DATE:
          return date
        case DATE_MODE.END_DATE_TIME:
          return `${getEndTimeOfTheDay(date)}${this.campusTimeZone}`
        case DATE_MODE.START_DATE_TIME:
          return `${getStartTimeOfTheDay(date)}${this.campusTimeZone}`
        case DATE_MODE.DATE_TIME_RANGE:
          return {
            start_date: `${getStartTimeOfTheDay(date)}${this.campusTimeZone}`,
            end_date: `${getEndTimeOfTheDay(date)}${this.campusTimeZone}`,
          }
      }
    },
  },
}
</script>
