<template>
  <ValidationObserver @submit="submitForm">
    <UiModalContainer
      footer-classes="flex gap-3"
      :disable-position-center="true"
      :modal-show="showModal"
      :modal-width="modalWidth"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>{{ userData.heading }}</span>
      </template>

      <UserDetailedView :user="userCandidate" @filter="getUserInfo" />
      <template v-slot:footer>
        <div class="flex gap-3 md:flex-row w-full flex-col">
          <div
            class="flex flex-1 px-1 text-xs items-center md:text-base font-bold text-primary-green"
          >
            <span class="text-label-text">{{ userData.message }}</span>
          </div>
          <div class="flex gap-3 justify-end rtl:gap-x-4">
            <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
            <UIButton button="primary">
              <template v-if="isLoading">
                <Loader></Loader>
              </template>
              <template v-else>
                <span>{{ userData.buttonText }}</span>
              </template>
            </UIButton>
          </div>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* Components */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'

/* 3rd party compononent */
import UserDetailedView from '@src/components/userDetailedView.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { transformGuardianData } from '@src/utils/settings/tenant-user.util.js'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import UIButton from '@src/components/UiElements/UIButton.vue'

/* VUEX */
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    Loader,
    UserDetailedView,
    UIButton,
  },
  emits: ['success'],
  data() {
    return {
      title: 'title',
      userCandidate: null,
      dashboard: 'dashboard',
      isLoading: false,
      userPassword: '',
      showModal: false,
      userData: {
        buttonText: 'Disable',
        message: 'Are you sure you want to Disable this user ? ',
        heading: 'Disable Modal',
      },
    }
  },
  computed: {
    modalWidth() {
      let response = this.userCandidate ? 60 : 40
      return response
    },
    ...mapState({
      showDisableModal: (state) => state.layout.showDisableModal,
      currentSelectedUser: (state) => state.layout.currentSelectedUser,
    }),
  },
  watch: {
    showDisableModal: {
      handler(value) {
        if (value) this.userCandidate = null
        this.getUserInfo(this.currentSelectedUser)
      },
    },
  },

  methods: {
    getUserInfo(data) {
      let queryParam = {
        skip: data?.range?.skip || 0,
        limit: data?.range?.limit || 5,
      }
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.userCandidate = res.data
          this.showModal = this.showDisableModal
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', false)

          break
      }
    },
    submitForm() {
      this.disableUserLogin(this.currentSelectedUser?.id).then(() => {
        this.handleClick('close')
        this.$emit('success')
      })
    },

    ...mapActions('users', ['getUserDetails']),
    ...mapActions('auth', ['disableUserLogin']),
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .primary-button {
    min-width: 83px;
  }
  .primary-button-outline {
    min-width: 83px;
  }
}
</style>
