<template>
  <ValidationObserver @submit="addNewRole">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Add Existing User as {{ userType }}</span>
      </template>
      <template v-slot>
        <div>
          <div>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="userRole.user_id"
                title="USERS"
                label="full_name"
                :options="allUsers"
                reduce="id"
                class="flex-1"
                :image="true"
                rules="required"
                :search-through-api="true"
                @selectedObj="showExitingUserDetail"
                @search="getAllUsers"
              />
            </InputFieldWrapper>
            <div v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></div>
            <UserDetailedView v-else :user="userCandidate" class="ltr:px-0 ltr:pt-0 ltr:pb-5" />

            <div
              v-if="isNotAnAdmin && showCampusSectionfields && !currentCampusScope"
              class="font-extrabold md:text-lg text-base border-t border-primary-grey pt-5 pb-3"
            >
              Select Campus and Section:
            </div>
            <div v-if="isNotAnAdmin && showCampusSectionfields">
              <InputFieldWrapper>
                <UiSingleSelect
                  v-model="userRole.campus_id"
                  title="CAMPUSES"
                  label="title"
                  :options="campuses"
                  :disabled="!!currentCampusScope"
                  class="flex-1"
                  reduce="id"
                  rules="required"
                  :search-through-api="true"
                  @search="getCampus"
                  @change="getClasses()"
                  @deSelect="getClasses()"
                />
                <UiSingleSelect
                  v-if="isNotAssignedRole"
                  v-model="userRole.class_id"
                  title="Class"
                  :options="usersClassList"
                  label="title"
                  reduce="id"
                  class="flex-1"
                  :search-through-api="true"
                  :disabled="currentClassScope || !userRole.campus_id"
                  @search="getClasses"
                  @change="setSection(userRole.class_id)"
                  @deSelect="setSection(userRole.class_id)"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <UiSingleSelect
                  v-if="isNotAssignedRole"
                  v-model="userRole.section_id"
                  label="title"
                  :options="usersSectionList"
                  :disabled="currentSectionScope || !userRole.class_id"
                  title="SECTS"
                  class="flex-1"
                  :rules="sectionRules"
                  reduce="id"
                  :search-through-api="true"
                  @search="getSection"
                />
              </InputFieldWrapper>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Save</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import UserDetailedView from '@src/components/userDetailedView.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UserDetailedView,
    UiModalContainer,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    userType: { type: String, default: 'Admin' },
    assigningRole: { type: String, default: 'super_admin' },
  },
  emits: ['toggle'],
  data() {
    return {
      showCampusSectionfields: false,
      userCandidate: null,
      dashboard: 'dashboard',
      userRole: {
        role_id: '',
        user_id: '',
        campus_id: '',
        section_id: '',
        class_id: '',
      },
      allUsers: [],
    }
  },
  computed: {
    ...mapState('layout', [
      'campuses',
      'isLoading',
      'currentCampusScope',
      'currentSectionScope',
      'currentClassScope',
      'currentSelectedUser',
    ]),
    ...mapGetters('auth', ['userRoles', 'currentUserId']),
    ...mapState('classes', ['usersClassList']),
    ...mapState('section', ['usersSectionList']),
    isNotAnAdmin() {
      return this.userRole.role_id !== 'super_admin'
    },
    sectionRules() {
      return this.currentClassScope || this.userRole.class_id ? 'required' : ''
    },
    isNotAssignedRole() {
      return ![
        TENANT_ROLES.CAMPUS_ADMIN,
        TENANT_ROLES.PICKUP_PERSON,
        TENANT_ROLES.GUARDIAN,
      ].includes(this.assigningRole)
    },
  },
  watch: {
    'userRole.campus_id': {
      handler() {
        this.userRole.class_id = ''
      },
    },
    'userRole.class_id': {
      handler() {
        this.userRole.section_id = ''
      },
    },
  },
  watch: {
    'userRole.campus_id': {
      handler() {
        if (!this.currentClassScope) this.userRole.class_id = ''
      },
    },
    'userRole.class_id': {
      handler() {
        if (!this.currentSectionScope) this.userRole.section_id = ''
      },
    },
  },
  created() {
    this.userRole.role_id = this.assigningRole
    this.getAllUsers()
    this.scopeHandler()
  },
  methods: {
    ...mapActions('staff', ['getUsers', 'setUserRole']),
    ...mapActions('instituteCount', ['getInstituteCount']),
    ...mapActions('users', ['getUserDetails']),
    ...mapActions('section', ['getSectionsList']),
    ...mapActions('classes', ['getUserClasses']),
    ...mapActions('layout', ['getCampusList']),
    ...mapActions('section', ['filterUsersSectionsList']),

    scopeHandler() {
      if (this.currentCampusScope && this.isNotAnAdmin) {
        this.userRole.campus_id = this.currentCampusScope.id
        this.getClasses()
      }
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    getSection(query = '') {
      this.setSection(this.userRole?.class_id, query)
    },
    setSection(id, query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', id || this.currentClassScope?.id),
        },
      }
      this.filterUsersSectionsList(payload)
    },
    getAllUsers(query = '') {
      let payload = {
        $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) },
      }
      this.getUsers(payload).then((res) => {
        this.allUsers = res.data.records
        this.allUsers.forEach((user) => {
          user.full_name = `${user.first_name} ${user.last_name}`
        })
      })
    },
    getClasses(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', this.userRole.campus_id),
        },
      }
      this.getUserClasses(payload).then((res) => {
        if (this.currentClassScope && this.isNotAnAdmin) {
          this.userRole.class_id = this.currentClassScope.id
          this.setSection(this.userRole.class_id)
        }
        if (this.currentSectionScope && this.isNotAnAdmin) {
          this.userRole.section_id = this.currentSectionScope?.id
        }
      })
    },
    showExitingUserDetail(selectedUser) {
      this.showCampusSectionfields = true
      this.userCandidate = selectedUser
      this.getUserInfo(this.userCandidate)
    },
    getUserInfo(data) {
      let queryParam = {
        skip: data?.range?.skip || 0,
        limit: data?.range?.limit || 5,
      }
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.userCandidate = res.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          {
            this.$emit('toggle')
          }
          break
      }
    },

    async addNewRole() {
      this.$store.commit('layout/IS_LOADING', true)
      removeEmptyKeysFromObject(this.userRole)
      await this.setUserRole(this.userRole)
        .then((response) => {
          if (
            this.currentUserId === response.data.user_id &&
            !this.userRoles.some((e) => e.role_id === response.data.role_id)
          )
            this.$store.commit('auth/ADD_ROLE', response.data)
          this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'role_count')
          this.getInstituteCount()
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
      this.$emit('toggle', true)
    },
  },
}
</script>
