<template>
  <div class="flex cursor-pointer user relative">
    <div v-if="user" class="flex" @mouseover="showTooltip()" @mouseleave="hideTooltip()">
      <div v-if="user.image" class="w-8.5 h-8.5">
        <img :src="user.image" class="h-full w-full rounded-full" />
      </div>
      <span
        v-else
        class="w-8.5 h-8.5 rounded-full flex justify-center items-center font-roboto font-normal text-sm leading-6 bg-primary-purple-50 text-primary-purple-600"
      >
        {{ initials(`${user.first_name} ${user.last_name}`) }}
      </span>
      <div
        class="capitalize text-left text-sm font-roboto font-normal text-text-color leading-6 truncate flex items-center pl-2.5"
      >
        <span>
          {{ getLabel(user)?.replaceAll('_', ' ') }}
        </span>
      </div>
    </div>
    <div v-else>{{ GENERAL.NOT_APPLICABLE }}</div>
    <div v-if="isTooltip" class="absolute z-10 rtl:-right-4">
      <UITooltip :image="true" :user="user" label="full_name" />
    </div>
  </div>
</template>

<script>
import UITooltip from '@src/components/UiElements/UITooltip.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import GENERAL from '@src/constants/general-constants'

export default {
  components: {
    UITooltip,
  },
  mixins: [generalUtil],
  props: {
    tooltip: { type: String, default: 'tooltip-top' },
    dropdownTopBottom: { type: String, default: 'top-10' },
    user: {
      type: [Array, Object],
      default: () => [],
    },
    nestedLabel: {
      type: Array,
      default: () => [],
    },
    nestedDetail: {
      type: Array,
      default: () => [],
    },
    label: { type: [String, Number], default: 'title' },
    url: { type: String, default: '' },
    detail: { type: [String, Number], default: '' },
    image: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTooltip: false,
      GENERAL,
    }
  },
  methods: {
    showTooltip() {
      this.isTooltip = true
    },
    /**
     * Hide Tooltip Item
     * @description Hide Tooltip chip detailed tooltip
     */
    hideTooltip() {
      this.isTooltip = false
    },
    /**
     * Get Label
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getLabel(option) {
      if (typeof option === 'object') {
        if (this.nestedLabel.length > 0) {
          let nested = option

          this.nestedLabel.forEach((op) => {
            if (nested) nested = nested[op]
          })

          return nested
        } else return option[this.label]
      }
      return option
    },
  },
}
</script>

<style lang="scss" scoped>
.user .tooltip-text {
  &::before {
    position: absolute;
    top: -7px;
    width: 13px;
    height: 13px;
    content: '';
    transform: rotate(45deg);
  }

  top: 35px;
  left: -8px;
  z-index: 1;
  min-height: 40px;
  padding: 5px;
  margin-left: 5px;
  overflow-y: hidden;
  color: rgb(12, 12, 12);
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0 0 3px 0.1px;
  transition: opacity 0.3s;
}
</style>
