import { removeEmptyKeysFromObject } from '@utils/generalUtil'
import { objectDeepCopy } from '@utils/generalUtil'
import FILTER_KEYS from '@src/constants/filter-constants.js'

export default {
  data() {
    return {
      $where: {},
      queries: {},
      appliedQueries: {},
    }
  },
  methods: {
    birthDateAfter(date) {
      this.filtersPayload.date_of_birth_after = date
    },
    birthDateBefore(date) {
      this.filtersPayload.date_of_birth_before = date
    },
    cancelHanlder() {
      Object.keys(this.filtersPayload).forEach((key) => {
        let isKeyNotExist = !this.appliedQueries.hasOwnProperty(key) && key !== 'displayedColumns'
        let isExistingKeyEmpty =
          this.appliedQueries.hasOwnProperty(key) &&
          key !== 'displayedColumns' &&
          this.appliedQueries[key].hasOwnProperty(FILTER_KEYS.ILIKE) &&
          this.appliedQueries[key][FILTER_KEYS.ILIKE] === '%%'

        if (isKeyNotExist || isExistingKeyEmpty) this.filtersPayload[key] = ''
      })
      this.filtersCount = this.getFiltersCount()
    },
    updateQuery(query) {
      let isExisted = false,
        existedQuery = {},
        existedKey = ''
      Object.keys(this.queries).forEach((key) => {
        if (Object.keys(query)[0] === key) {
          isExisted = true
          existedQuery = this.queries[key]
          existedKey = key
        }
      })
      if (isExisted && typeof existedQuery === 'object' && query[existedKey] !== null) {
        existedQuery = { ...existedQuery, ...query[existedKey] }
        this.queries = { ...this.queries, [existedKey]: existedQuery }
      } else this.queries = { ...this.queries, ...query }

      removeEmptyKeysFromObject(this.queries)

      isExisted = false
    },
    clearFilters() {
      Object.keys(this.filtersPayload).forEach((key) => {
        if (!['displayedColumns'].includes(key)) this.filtersPayload[key] = ''
      })
      this.queries = {}
      this.$emit('apply', {
        $where: this.queries,
        displayedColumns: this.filtersPayload?.displayedColumns,
      })
      this.filtersCount = this.getFiltersCount()
      this.appliedQueries = {}
    },
    emitFiltersPayload(event, invalid) {
      this.filtersPayload.displayedColumns = event.displayedColumns
      if (invalid) return
      this.appliedQueries = objectDeepCopy(this.queries)
      this.$emit('apply', {
        $where: this.queries,
        ...this.filtersPayload,
      })
      this.filtersCount = this.getFiltersCount()
    },
    dueDateAfter(date) {
      this.filtersPayload.due_date_after = date
    },
    createdDateBefore(date) {
      this.filtersPayload.inserted_at_date_before = date
    },
    createdDateAfter(date) {
      this.filtersPayload.inserted_at_date_after = date
    },
    dueDateBefore(date) {
      this.filtersPayload.due_date_before = date
    },
    paidDateAfter(date) {
      this.filtersPayload.paid_after = date
    },
    paidDateBefore(date) {
      this.filtersPayload.paid_before = date
    },

    updateDisplayedColumnList() {
      if (this.alreadySelectedDisplayedColumns && this.alreadySelectedDisplayedColumns.length)
        this.filtersPayload.displayedColumns = this.alreadySelectedDisplayedColumns
    },
    dueDateAfter(date) {
      this.filtersPayload.due_date_after = date
    },
    dueDateBefore(date) {
      this.filtersPayload.due_date_before = date
    },
  },
}
