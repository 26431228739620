<template>
  <div :class="layoutView">
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue'

// Define the props
const props = defineProps({
  layoutMode: { default: false, type: Boolean },
  customClasses: { default: 'sm:flex-row flex-col', type: String },
})

// Compute the layout view based on the layoutMode and customClasses props
const layoutView = computed(() => {
  return props.layoutMode
    ? 'grid sm:grid-cols-2 xl:grid-cols-3 xl:gap-x-5 md:gap-x-4 gap-x-3'
    : `flex gap-2 mt-1 sm:gap-6 ${props.customClasses}`
})
</script>
