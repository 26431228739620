<template>
  <ValidationObserver @submit="submitResetPassword">
    <UiModalContainer
      footer-classes="flex gap-3"
      :disable-position-center="true"
      :modal-show="showModal"
      :modal-width="modalWidth"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>{{ userData.heading }}</span>
      </template>
      <UserDetailedView :user="userCandidate" @filter="getUserInfo" />
      <InputFieldWrapper class="border-t-2 border-primary-purple-100 pt-6">
        <UiInput
          v-model.trim="userPassword"
          vid="confirmation"
          type="password"
          name="Password"
          title="Password"
          label="Password"
          placeholder="Password"
          class="flex-1"
          rules="min:8|required"
          autocomplete="new-password"
        />
        <UiInput
          v-model.trim="confirmedPassword"
          type="password"
          name="CONF_PASS"
          title="CONF_PASS"
          label="Confirm Password"
          placeholder="Confirm Password"
          class="flex-1"
          rules="required|confirmed:@Password"
        />
      </InputFieldWrapper>
      <template v-slot:footer>
        <div class="flex gap-3 md:flex-row w-full flex-col">
          <div
            class="flex flex-1 px-1 text-xs items-center md:text-base font-bold text-primary-green"
          >
            <span class="text-label-text">{{ userData.message }}</span>
          </div>
          <div class="flex gap-3 justify-end rtl:gap-x-4">
            <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
            <UIButton button="primary">
              <template v-if="isLoading">
                <Loader></Loader>
              </template>
              <template v-else>
                <span>{{ userData.buttonText }}</span>
              </template>
            </UIButton>
          </div>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* Components */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'

/* 3rd party compononent */
import UserDetailedView from '@src/components/userDetailedView.vue'
import { Form as ValidationObserver } from 'vee-validate'

import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { transformGuardianData } from '@src/utils/settings/tenant-user.util.js'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

/* VUEX */
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    Loader,
    UserDetailedView,
    UiInput,
    UIButton,
    InputFieldWrapper,
  },
  emits: ['success'],
  data() {
    return {
      GENERAL_CONSTANTS,
      confirmedPassword: '',
      title: 'title',
      userCandidate: null,
      dashboard: 'dashboard',
      isLoading: false,
      userPassword: '',
      showModal: false,
      userData: {
        buttonText: 'Reset',
        message: 'Are you sure you want to Reset the Password ? ',
        heading: 'Reset Password',
      },
    }
  },
  computed: {
    modalWidth() {
      let response = this.userCandidate ? 60 : 40
      return response
    },
    ...mapState({
      resetPassword: (state) => state.layout.resetPassword,
      currentSelectedUser: (state) => state.layout.currentSelectedUser,
    }),
  },
  watch: {
    resetPassword: {
      handler(value) {
        if (value) {
          this.userPassword = null
          this.userCandidate = null
          this.confirmedPassword = ''
        }
        this.getUserInfo(this.currentSelectedUser)
      },
    },
  },
  methods: {
    getUserInfo(data) {
      let queryParam = {
        skip: data?.range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: data?.range?.limit || GENERAL_CONSTANTS.MODAL_RECORD_LIMIT,
      }
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.userCandidate = res.data
          this.showModal = this.resetPassword
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', false)

          break
      }
    },
    submitResetPassword() {
      let data = {
        id: this.currentSelectedUser?.id,
        password: this.userPassword,
      }
      this.resetUserPassword(data).then(() => {
        this.handleClick('close')
        this.$emit('success')
      })
    },

    ...mapActions('users', ['getUserDetails']),
    ...mapActions('institute', ['resetUserPassword']),
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .primary-button {
    min-width: 83px;
  }
  .primary-button-outline {
    min-width: 83px;
  }
}
</style>
