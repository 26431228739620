<template>
  <th
    ref="tableHeading"
    class="text-xs font-normal text-primary-gray-500 lg:px-6 px-3.5 min-w-[140px] capitalize"
  >
    <span class="w-full">
      <slot />
    </span>
  </th>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const tableHeading = ref(null)

onMounted(() => {
  if (tableHeading.value.innerText === 'Actions') {
    tableHeading.value.classList.add('w-35')
  }
})
</script>
