<template>
  <div
    class="min-w-[70px] max-w-fit h-[22px] flex items-center justify-center gap-1.5 rounded-2xl p-2"
    :class="containerClass"
  >
    <div class="w-2 h-2 flex justify-center items-center">
      <div class="w-1.5 h-1.5 rounded-full" :class="statusDotClass"></div>
    </div>
    <div class="font-medium text-xs font-inter capitalize" :class="titleClass">
      {{ titleValue }}
    </div>
  </div>
</template>

<script>
import { ROLES_LIST, TENANT_ROLES } from '@src/constants/user-roles-constants.js'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    success: {
      type: Boolean,
      default: false,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      containerClass: '',
      statusDotClass: '',
      titleClass: '',
    }
  },
  created() {
    this.titleValue = this.isUser ? this.setUserTitle(this.title) : this.title
    if (this.isUser) {
      this.setUserColorGrid(this.title)
    } else {
      this.setDefaultColorGrid(this.success)
    }
  },
  methods: {
    setUserTitle(value) {
      for (let role of ROLES_LIST) {
        if (role.value === value) {
          return role.title
        }
      }
    },
    setDefaultColorGrid(success) {
      if (success) {
        this.containerClass = 'bg-primary-purple-50'
        this.statusDotClass = 'bg-primary-purple-600'
        this.titleClass = 'text-primary-purple-600'
      } else {
        this.containerClass = 'bg-error-50'
        this.statusDotClass = 'bg-error-600'
        this.titleClass = 'text-error-600'
      }
    },
    setUserColorGrid(title) {
      switch (title) {
        case TENANT_ROLES.SUPER_ADMIN:
          this.containerClass = 'bg-primary-light-teal-50'
          this.statusDotClass = 'bg-primary-light-teal-600'
          this.titleClass = 'text-primary-light-teal-600'
          break
        case TENANT_ROLES.STAFF:
          this.containerClass = 'bg-primary-light-green-50'
          this.statusDotClass = 'bg-primary-light-green-600'
          this.titleClass = 'text-primary-light-green-600'
          break
        case TENANT_ROLES.SECTION_TEACHER:
          this.containerClass = 'bg-error-50'
          this.statusDotClass = 'bg-error-600'
          this.titleClass = 'text-error-600'
          break
        case TENANT_ROLES.CAMPUS_ADMIN:
          this.containerClass = 'bg-primary-purple-50'
          this.statusDotClass = 'bg-primary-purple-600'
          this.titleClass = 'text-primary-purple-600'
          break

        case TENANT_ROLES.GUARDIAN:
          this.containerClass = 'bg-primary-light-orange-50'
          this.statusDotClass = 'bg-primary-light-orange-600'
          this.titleClass = 'text-primary-light-orange-600'
          break
        case TENANT_ROLES.SECTION_STUDENT:
          this.containerClass = 'bg-primary-light-blue-50'
          this.statusDotClass = 'bg-primary-light-blue-600'
          this.titleClass = 'text-primary-light-blue-600'
          break
        case TENANT_ROLES.PICKUP_PERSON:
          this.containerClass = 'bg-primary-purple-50'
          this.statusDotClass = 'bg-primary-purple-600'
          this.titleClass = 'text-primary-purple-600'
          break
      }
    },
  },
}
</script>
