<template>
  <ValidationObserver @submit="submitForm">
    <UiModalContainer
      footer-classes="flex gap-3"
      :disable-position-center="true"
      :modal-show="showModal"
      :modal-width="modalWidth"
      @handleClick="handleClose"
    >
      <template v-slot:header>
        <span>{{ userData.heading }}</span>
      </template>
      <UserDetailedView :user="userCandidate" @filter="getUserInfo" />
      <InputFieldWrapper
        v-if="showPhoneNumberAndEmailFields"
        class="border-t-2 border-primary-100 pt-6"
      >
        <UiInput
          v-if="!currentSelectedUser.email"
          v-model="enableLoginData.email"
          type="email"
          name="Email"
          title="Email"
          label="Email"
          class="flex-1"
          placeholder="Email"
          :rules="emailRules"
        />
        <PhoneNumber
          v-if="!currentSelectedUser.phone"
          v-model="adminNumber"
          title="Phone Number"
          class="flex-1"
          :rules="phoneNumberRules"
          @update="updateAdminNumber"
        />
      </InputFieldWrapper>
      <template v-slot:footer>
        <div class="flex gap-3 md:flex-row w-full flex-col">
          <div
            class="flex flex-1 px-1 text-xs items-center md:text-base font-bold text-primary-green"
          >
            <span class="text-label-text">{{ userData.message }}</span>
          </div>
          <div class="flex gap-3 justify-end rtl:gap-x-4">
            <UIButton :disabled="isLoading" @click="handleClose('close')">Cancel</UIButton>
            <UIButton button="primary">
              <template v-if="isLoading">
                <Loader></Loader>
              </template>
              <template v-else>
                <span>{{ userData.buttonText }}</span>
              </template>
            </UIButton>
          </div>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* Components */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'

/* 3rd party compononent */
import UserDetailedView from '@src/components/userDetailedView.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { transformGuardianData } from '@src/utils/settings/tenant-user.util.js'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import { objectToFormData } from '@utils/generalUtil'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

/* VUEX */
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    Loader,
    UserDetailedView,
    UiInput,
    PhoneNumber,
    UIButton,
    InputFieldWrapper,
  },
  emits: ['success'],

  data() {
    return {
      adminNumber: '',
      title: 'title',
      userCandidate: null,
      dashboard: 'dashboard',
      isLoading: false,
      showModal: false,
      enableLoginData: {
        email: '',
        phone: '',
        password: null,
        auto_generate_password: '',
        email_status: '',
        sms_status: '',
      },
      userData: {
        buttonText: 'Enable',
        message: 'Are you sure you want to Enable this User ? ',
        heading: 'Enable Login',
      },
    }
  },
  computed: {
    modalWidth() {
      let response = this.userCandidate ? 60 : 40
      return response
    },
    showPhoneNumberAndEmailFields() {
      return (
        this.currentSelectedUser &&
        (!this.currentSelectedUser.phone || !this.currentSelectedUser.email)
      )
    },
    emailRules() {
      return !this.adminNumber || this.emailService ? 'required|email' : 'email'
    },
    phoneNumberRules() {
      return !this.enableLoginData.email || this.smsService ? 'required|validNumber' : 'validNumber'
    },
    ...mapState({
      enableLoginModal: (state) => state.layout.enableLoginModal,
      currentSelectedUser: (state) => state.layout.currentSelectedUser,
    }),
  },
  watch: {
    enableLoginModal: {
      handler(value) {
        if (value) {
          this.userCandidate = null
          this.adminNumber = null
          const { email, phone, password } = this.currentSelectedUser
          this.enableLoginData = { email, phone, password }
          this.adminNumber = this.currentSelectedUser?.phone || ''
          this.getUserInfo(this.currentSelectedUser)
          this.getServices(this.currentSelectedUser?.id)
        } else {
          this.showModal = false
        }
      },
    },
  },
  methods: {
    updateAdminNumber(payload) {
      this.enableLoginData.phone = payload?.formattedNumber
    },
    getUserInfo(data) {
      let queryParam = {
        skip: data?.range?.skip || 0,
        limit: data?.range?.limit || 5,
      }
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.userCandidate = res.data
          this.showModal = this.enableLoginModal
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async getServices() {
      const [res, err] = await this.getSmsEmailConfig()
      this.emailEnabled = res.data.email_status
      this.smsEnabled = res.data.sms_status
    },
    handleClose() {
      this.$store.commit('layout/ENABLE_LOGIN_MODAL', false)
    },
    submitForm() {
      this.enableLoginData.auto_generate_password = this.autoGeneratePassword
      this.enableLoginData.email_status = this.emailService
      this.enableLoginData.sms_status = this.smsService
      const formData = objectToFormData(this.enableLoginData, this.currentSelectedUser, true, false)
      let data = {
        id: this.currentSelectedUser?.id,
        user: formData,
      }
      this.isLoading = true
      this.enableUserLogin(data)
        .then(() => {
          this.handleClose()
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    ...mapActions('users', ['getUserDetails']),
    ...mapActions('notifications', ['getSmsEmailConfig']),
    ...mapActions('auth', ['enableUserLogin']),
  },
}
</script>
