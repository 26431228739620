<template>
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="modal"
    :modal-width="30"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-i18n="dashboard">{{ heading }}</span>
    </template>
    <div>
      <div v-if="details">
        <span class="text-label-text mb-5">{{ message }}</span>
        <div v-for="(detail, key, index) in details" :key="index" class="w-2/5 mt-1">
          <div class="flex gap-5">
            <div class="font-medium flex-1 whitespace-nowrap min-w-2">
              <span>{{ transformTextToCapitalize(key) }}:</span>
            </div>
            <div class="text-primary-green font-medium capitalize flex-1">
              {{ detail || detail === 0 ? detail : GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center text-2xl text-primary-gray-800">
        <span>{{ message }}</span>
        <span class="capitalize text-primary-purple-600">{{ name }}</span>
        <span v-if="remainingMessage" class="ml-1">{{ remainingMessage }}</span>
      </div>
    </div>
    <div class="mb-6">
      <slot name="body"></slot>
    </div>
    <template v-slot:footer>
      <div class="flex gap-3 rtl:gap-x-4">
        <UIButton @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary" :disabled="isLoading" @click="handleClick('confirm')">
          <span v-if="isLoading">
            <Loader />
          </span>
          <span v-else>{{ buttonText }}</span>
        </UIButton>
      </div>
    </template>
  </UiModalContainer>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { transformTextToCapitalize } from '@utils/generalUtil'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    UiModalContainer,
    Loader,
    UIButton,
  },
  props: {
    modal: { type: Boolean, default: false },
    heading: { type: String, default: 'Alert' },
    message: { type: String, default: 'Are you sure?' },
    remainingMessage: { type: String, default: '' },
    name: { type: String, default: '' },
    buttonText: { type: String, default: 'Confirm' },
    isLoading: { type: Boolean, default: false },
    details: { type: Object, default: () => {} },
  },
  emits: ['cancel', 'confirm'],

  data() {
    return {
      GENERAL_CONSTANTS,
      title: 'title',
      dashboard: 'dashboard',
    }
  },
  methods: {
    transformTextToCapitalize,
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('cancel')
          break
        case 'confirm':
          this.$emit('confirm')
          break
      }
    },
  },
}
</script>
