<template>
  <div class="font-inter">
    <div v-if="phone && phoneNumber">
      <div class="flex text-sm gap-1.5 text-primary-gray-900 items-center">
        <span class="min-w-[20px]">{{ countryAbbreviation }}</span>
        <span class="border-[1.5px] h-3 border-primary-gray-400"></span>
        <div class="flex gap-1 text-primary-gray-500">
          <span>{{ countryCode }}</span>
          <span>{{ nationalNumber }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
    </div>
  </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js'

import GENERAL_CONSTANTS from '@src/constants/general-constants.js'

export default {
  props: {
    phone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      phoneNumber: '',
      countryAbbreviation: '',
      countryCode: '',
      nationalNumber: '',
    }
  },
  created() {
    if (this.phone) this.number()
  },
  methods: {
    number() {
      this.phoneNumber = parsePhoneNumber(this.phone)
      if (this.phoneNumber) {
        this.countryAbbreviation = this.phoneNumber.country
        this.countryCode = '+' + this.phoneNumber.countryCallingCode
        this.nationalNumber = this.phoneNumber.nationalNumber
      }
    },
  },
}
</script>
