<template>
  <ValidationObserver @submit="submitForm">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      :disable-position-center="true"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="dashboard">EDIT_SUPER_ADMIN</span>
      </template>
      <div class="checkInModal-body">
        <div>
          <div class="flex-1 sm:flex xl:gap-6 lg:gap-5 sm:gap-4">
            <div class="flex-1 flex flex-col">
              <UiInput
                v-model.trim="superAdminData.super_admin_info.first_name"
                type="text"
                name="First Name"
                title="First Name"
                label="First Name"
                placeholder="Enter First Name"
                rules="alpha_spaces"
              />
              <UiInput
                v-model.trim="superAdminData.super_admin_info.last_name"
                type="text"
                name="Last Name"
                title="Last Name"
                label="Last Name"
                placeholder="Enter Last Name"
                rules="alpha_spaces"
              />
              <!-- alpha_dash -->
              <UiSingleSelect
                v-model="superAdminData.super_admin_info.gender"
                title="Gender"
                :options="genderArray"
              />
              <UiSingleSelect
                v-model="superAdminData.profile_info.blood_group"
                title="Blood Group"
                :options="bloodGroupArray"
              />
              <UiDatePicker v-model="superAdminData.profile_info.date_of_birth" title="DOB" />
              <UiSingleSelect
                v-model="superAdminData.identity_info.type"
                title="Identity Type"
                :options="IDENTITY_TYPE_OPTIONS"
                reduce="value"
                class="flex-1"
                @change="resetIdentityInfo()"
              />
              <div v-if="superAdminData.identity_info.type">
                <UiInput
                  v-model="superAdminData.identity_info.value"
                  type="text"
                  name="Identity Number"
                  title="Identity Number"
                  label="Identity Number"
                  placeholder="Enter Identity No"
                  class="flex-1"
                  rules="required|numeric"
                />
                <UiInput
                  v-model="superAdminData.identity_info.country"
                  type="text"
                  name="Country"
                  title="Country"
                  label="Country"
                  placeholder="Enter Country"
                  class="flex-1"
                  rules="required|alpha"
                />
              </div>
            </div>
            <div class="flex-1">
              <UiInput
                id="img-btn"
                ref="imgUpload"
                v-model="adminImage"
                title="Photo"
                placeholder="Photo Upload"
                :preview-image="previewImage"
                type="file"
                :rules="imageRules"
                @preview="preview"
                @clearValue="clearValue"
              />
              <PhoneNumber
                v-model="adminPhoneNumber"
                title="Phone Number"
                :rules="
                  isAdminActivate && !superAdminData.super_admin_info.email
                    ? 'required|validNumber'
                    : 'validNumber'
                "
                @update="updateAdminNumber"
              />

              <UiInput
                v-model="superAdminData.super_admin_info.email"
                type="email"
                name="Email"
                title="Email"
                label="Email"
                placeholder="Email"
                :rules="isAdminActivate && !adminPhoneNumber ? 'required|email' : 'email'"
              />
              <UiInput
                v-model.trim="superAdminData.profile_info.address"
                type="text"
                name="Address"
                title="Address"
                label="Address"
                placeholder="Address"
                class="flex-1"
              />
              <UiInput
                v-model.trim="superAdminData.profile_info.religion"
                type="text"
                name="Religion"
                title="Religion"
                label="Religion"
                placeholder="Religion"
                rules="alpha_spaces"
              />
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex space-x-2 rtl:gap-x-4">
          <button
            v-i18n="dashboard"
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 rtl:ml-3 flex justify-center items-center primary-button-outline"
            :disabled="isLoading"
            @click="handleClick('close')"
          >
            Cancel
          </button>

          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
            :disabled="isLoading"
            type="submit"
          >
            <div v-if="isLoading">
              <Loader></Loader>
            </div>
            <div v-else>
              {{ btnText }}
            </div>
          </button>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* COMPONENTS */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import { IDENTITY_TYPE_OPTIONS } from '@src/constants/general-constants.js'
/* MIXINS */
import generalMixin from '@src/mixins/general-mixins.js'
/* STATE */
import { mapActions, mapState } from 'vuex'
/* 3rd party components */
import { Form as ValidationObserver } from 'vee-validate'

/* Utils */
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'

import { transformSuperAdminData } from '@src/utils/settings/tenant-user.util.js'

/* EXPORT AND RENDER */
export default {
  components: {
    UiModalContainer,
    UiInput,
    UiDatePicker,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    PhoneNumber,
  },
  mixins: [generalMixin],
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      IDENTITY_TYPE_OPTIONS,
      adminPhoneNumber: '',
      options: ['Select'],
      previewImage: null,
      currentStat: 1,
      loadClassList: [],
      adminImage: null,
      dashboard: 'dashboard',
      genderArray: [this.$t(`dashboard.male`), this.$t(`dashboard.female`)],
      bloodGroupArray: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      isAdminActivate: true,
      superAdminData: {
        super_admin_info: {
          first_name: '',
          last_name: '',
          email: '',
          gender: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          address: '',
        },
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
      },
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),

    btnText() {
      if (this.edit && this.currentStat < 1) return 'Next'
      else return 'Update'
    },
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },
  },

  created() {
    if (this.edit) this.setAdminData()
  },
  methods: {
    resetIdentityInfo() {
      this.superAdminData.identity_info.value = ''
      this.superAdminData.identity_info.country = ''
    },
    updateAdminNumber(payload) {
      this.superAdminData.phone = payload.formattedNumber
    },
    /**
     * Set previous step inactive and mark its status true
     * Set next step active and mark its status false
     */
    nextStep() {
      if (this.currentStat < this.steps.length) {
        this.steps[this.currentStat - 1].active = false
        this.steps[this.currentStat - 1].markStatus = true
        this.currentStat += 1
        this.steps[this.currentStat - 1].markStatus = false
        this.steps[this.currentStat - 1].active = true
      }
    },
    /**
     * Set selecte image to preview
     * @param {Object} image- selected image
     */
    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.adminImage = image
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.previewImage = null
          break
        case 'cancel':
          break
        case 'confirm':
          break
      }
    },
    submitForm() {
      if (this.password) this.superAdminData.password = this.password
      this.editAdmin()
    },

    /**
     * Edit existing admin
     * Append admin data and its profile picture in form data
     * Send put request with form data
     */

    editAdmin() {
      this.$store.commit('layout/IS_LOADING', true)
      this.superAdminData.login_activated_at = this.isAdminActivate
        ? currentUTCDateTimeWithZone()
        : ''

      const form = new FormData()
      if (this.adminImage) form.append('profile_image', this.adminImage)
      if (this.adminPhoneNumber) form.append('phone', this.adminPhoneNumber)
      form.append('gender', this.superAdminData?.super_admin_info?.gender || '')
      form.append('first_name', this.superAdminData?.super_admin_info?.first_name || '')
      form.append('last_name', this.superAdminData?.super_admin_info?.last_name || '')
      form.append('email', this.superAdminData?.super_admin_info?.email || '')
      form.append('user_type', 'super-admin')
      form.append('address', this.superAdminData?.profile_info?.address || '')
      form.append('login_activated_at', this.superAdminData?.login_activated_at || '')
      form.append('blood_group', this.superAdminData?.profile_info?.blood_group || '')
      form.append('date_of_birth', this.superAdminData?.profile_info?.date_of_birth || '')
      form.append('religion', this.superAdminData?.profile_info?.religion || '')
      form.append('height', this.superAdminData?.profile?.height || '')
      form.append('weight', this.superAdminData?.profile?.weight || '')
      form.append('type', this.superAdminData?.identity_info.type || '')
      form.append('value', this.superAdminData?.identity_info.value || '')
      form.append('country', this.superAdminData?.identity_info.country || '')

      const data = {
        admin: form,
        id: this.edit?.id,
      }
      this.editSuperAdmin(data)
        .then((res) => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.previewImage = null
        })
    },

    setAdminData() {
      this.superAdminData = transformSuperAdminData(this.edit)
      this.isAdminActivate = !!this.edit?.login_activated_at
      this.previewImage = {}
      this.previewImage.src = this.edit?.image || (this.previewImage = null)
      this.adminPhoneNumber = this.edit?.phone
    },
    /**
     * STORE METHODS
     */
    ...mapActions('superadmin', ['editSuperAdmin']),
  },
}
</script>

<style lang="scss" scoped>
.active {
  .step-counter {
    color: var(--white);
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 7px 14px var(--primary-green-dark);
  }
  .step-info {
    color: var(--text-color);
  }
}
</style>
