<template>
  <div class="bg-white relative mt-5 rounded-lg">
    <div
      :id="id"
      class="overflow-x-auto rounded-lg border border-primary-gray-200"
      @scroll.passive="scrollDetect"
    >
      <table class="w-full table-auto whitespace-nowrap divide-y divide-primary-gray-200">
        <slot />
      </table>
    </div>
    <div
      v-if="rightBlurArea"
      class="w-5 bg-primary-purple-25 absolute top-0 -right-0.25 opacity-80 blur-right-side rounded-tr-lg"
    ></div>
    <div
      v-if="leftBlurArea"
      class="w-5 bg-primary-purple-25 absolute top-0 -left-0.25 opacity-80 blur-left-side rounded-tl-lg"
    ></div>

    <div
      v-if="leftBlurArea && swipeLeft"
      class="absolute left-0 top-0 z-[1] w-10 h-10 shadow-swipe bg-primary-purple-25 p-2 rounded-lg"
    >
      <icon icon="swipe" />
    </div>
    <div
      v-if="rightBlurArea && swipeRight"
      class="absolute right-0 top-0 w-10 h-10 shadow-swipe bg-primary-purple-25 p-2 rounded-lg"
    >
      <icon icon="swipe" />
    </div>
  </div>
</template>

<script>
import generalMixin from '@src/mixins/general-mixins.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import fileMixins from '@/src/mixins/file-mixins'
import icon from '@components/icons/icon.vue'

export default {
  components: {
    icon,
  },
  mixins: [scrollMixin, fileMixins, generalMixin],
  props: {
    id: {
      type: String,
      default: 'table-container',
    },
  },
  created() {
    this.tableId = this.id
  },
}
</script>
