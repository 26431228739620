<template>
  <div>
    <SuperAdminFilters
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="setFilterData"
    />
    <template v-if="isLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else-if="!isLoading && isEmpty(adminList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Actions')" v-if="isOwner">Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(admin, index) in adminList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <SingleUserDisplay :user="admin" label="full_name" :image="true" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Phone Number', ['Phone Number'])">
            <DisplayPhoneNumber :phone="admin.phone" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
            {{ admin.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Date of Birth')">
            {{ (admin.profile && admin.profile.date_of_birth) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Address')">
            {{ (admin.profile && admin.profile.address) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <UiStatus
              :title="
                admin.login_activated_at
                  ? GENERAL_CONSTANTS.LOGIN_ENABLED
                  : GENERAL_CONSTANTS.LOGIN_DISABLED
              "
              :success="!!admin.login_activated_at"
            />
          </TD>
          <TD v-if="isOwner" v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="admin"
              :idx="index"
              :action-list="admin.actionsList"
              @action="superAdminAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(adminCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="getSuperAdmins"
    />

    <!-- User Activation Modal -->
    <UserEnableModal @success="fetchData" />
    <!-- Edit Super Admin Modal -->
    <EditSuperAdminModal
      v-if="showSuperAdminModal"
      :modal="showSuperAdminModal"
      :edit="currentAdmin"
      @toggle="toggleSuperAdminModal"
    />
    <UIConfirmationModal
      v-if="isOpen"
      heading="REVOKE_ACESS"
      message="Are you sure, you want to revoke the Super Admin Access of "
      button-text="Revoke"
      :name="currentAdmin.full_name"
      remaining-message="?"
      :modal="isOpen"
      @cancel="toggleRevokeAccessConfirmationModal"
      @confirm="revokeRoleAccess"
    />

    <UserDeactivateModal @success="fetchData" />
    <!-- Reset Password -->
    <ResetPasswordModal @success="fetchData" />
    <RoleModal
      v-if="isRoleModal"
      :modal="isRoleModal"
      user-type="Super Admin"
      assigning-role="super_admin"
      @toggle="toggleRoleModal"
    />
    <UserDisableModal @success="fetchData" />
    <UIConfirmationModal
      v-if="isSetupPassword"
      heading="User Setup Password Email"
      :message="GENERAL_CONSTANTS.emailSetupMessage"
      button-text="Sent"
      :modal="isSetupPassword"
      :name="currentAdmin?.full_name"
      remaining-message=""
      @cancel="toggleUserPasswordSetupConfirmationModal"
      @confirm="sentSetupPasswordEmailHandler"
    />
  </div>
</template>

<script>
import { objectDeepCopy, showColumnInDataTable } from '@/src/utils/generalUtil.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
import SuperAdminFilters from '@views/super-admin/SuperAdminFilters.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import { isEmpty } from 'lodash'
import Pagination from '@components/BaseComponent/Pagination.vue'
import EditSuperAdminModal from '@views/super-admin/modals/EditSuperAdminModal.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import TableAction from '@/src/components/TableAction.vue'
import { getOptionsBasedOnUserStatus } from '@src/utils/settings/tenant-user.util.js'
import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import RoleModal from '@views/admin/modals/RolesModal.vue'
import ResetPasswordModal from '@src/components/action-components/ResetPasswordModal.vue'
import fileMixins from '@/src/mixins/file-mixins'
import UserEnableModal from '@src/components/action-components/EnableLoginModal.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import UserDisableModal from '@src/components/action-components/DisableModal.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  superAdminGraphChart,
  rightBarStats,
} from '@/src/router/views/super-admin/super-admin.util.js'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import superAdminData from '@/src/router/views/super-admin/super-admin.json'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    SingleUserDisplay,
    UserDisableModal,
    Loader,
    TableAction,
    RoleModal,
    UIConfirmationModal,
    Pagination,
    UserEnableModal,
    SuperAdminFilters,
    UserDeactivateModal,
    ResetPasswordModal,
    EditSuperAdminModal,
    DisplayPhoneNumber,
    NoRecordFound,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalUtil, fileMixins, scrollMixin],

  data() {
    return {
      forceRender: 0,
      GENERAL_CONSTANTS,
      isEmpty,
      isLoading: false,
      dashboard: 'dashboard',
      isRoleModal: false,
      filtersData: {},
      showSuperAdminModal: false,
      showPagination: false,
      adminCounts: 0,
      isOpen: false,
      currentAdmin: null,
      skipForFilterColumns: superAdminData.skipForFilterColumns,
      defaultSelectedColumns: superAdminData.defaultSelectedColumns,
      tableHeaders: superAdminData.tableHeaders,
      adminList: [],
      isSetupPassword: false,
      adminStats: [],
      loginUser: {},
    }
  },
  page: superAdminData.page,
  computed: {
    ...mapState({
      tabRightBar: (state) => state.layout.tabRightNav,
      enableLoginModal: (state) => state.layout.enableLoginModal,
      showDeactivateModal: (state) => state.layout.showDeactivateModal,
      showDisableModal: (state) => state.layout.showDisableModal,
      resetPassword: (state) => state.layout.resetPassword,
      showUiModal: (state) => state.layout.showUiModal,
    }),
    modalValueChanges() {
      return (
        this.enableLoginModal ||
        this.showDeactivateModal ||
        this.showDisableModal ||
        this.resetPassword
      )
    },
    ...mapGetters('auth', ['isOwner']),
    ...mapGetters('layout', ['isManualPasswordResetAllowed']),
  },
  watch: {
    modalValueChanges: {
      handler(value) {
        if (!value) this.currentAdmin = null
      },
    },
    showUiModal: {
      handler(value) {
        if (value) this.toggleRoleModal()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchSuperAdminStats()
      },
    },
  },

  created() {
    this.setRightBarData()
    this.getSuperAdmins()
  },

  methods: {
    showColumnInDataTable,
    setFilterData(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.getSuperAdmins()
    },
    toggleSuperAdminModal(payload) {
      this.showSuperAdminModal = !this.showSuperAdminModal
      if (!this.showSuperAdminModal) this.currentAdmin = null
      if (payload) {
        this.getSuperAdmins()
        this.forceRender++
      }
    },
    fetchData() {
      this.forceRender++
      this.getSuperAdmins()
    },
    superAdminAction(action, current) {
      this.currentAdmin = current
      this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentAdmin)
      switch (action) {
        case 'Edit':
          this.toggleSuperAdminModal()
          break
        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)

          break
        case 'REVOKE_ACCESS':
          this.toggleRevokeAccessConfirmationModal()

          break
        case 'TV_ACTIVATE_ACCOUNT':
          this.$store.commit('layout/ENABLE_LOGIN_MODAL', true)
          break
        case 'TV_SUSPEND_ACCOUNT':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', true)
          break
        case 'TV_RP':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', true)
          break
        case 'TV_RE':
          this.toggleUserPasswordSetupConfirmationModal()
          break
      }
    },
    toggleRevokeAccessConfirmationModal() {
      this.isOpen = !this.isOpen
    },
    toggleUserPasswordSetupConfirmationModal() {
      this.isSetupPassword = !this.isSetupPassword
      if (!this.isSetupPassword) this.currentAdmin = null
    },

    async sentSetupPasswordEmailHandler() {
      let payload = { user_id: this.currentAdmin?.id }
      await this.resendUserSetupPassword(payload)
      this.toggleUserPasswordSetupConfirmationModal()
    },
    revokeRoleAccess() {
      this.revokeUserEntityPermission(this.currentAdmin.role_id).then((res) => {
        this.getSuperAdmins()
        this.$store.commit(
          'toast/NEW',
          { message: res.data.message, type: 'success' },
          { root: true },
        )
        this.toggleRevokeAccessConfirmationModal()
      })
    },
    fetchSuperAdminStats(payload = { $where: {} }) {
      this.getSuperAdminStats(payload).then((res) => {
        this.setRightBarData(res)
      })
    },
    toggleRoleModal(payload) {
      this.isRoleModal = !this.isRoleModal
      if (!this.isRoleModal) this.$store.dispatch('layout/setShowUiModal', false)
      if (payload) {
        this.getSuperAdmins()
        this.forceRender++
      }
    },
    setRightBarData(statData) {
      let content = {
        header: {
          title: 'ALL_SUPER_ADMIN',
          buttons: [
            {
              title: 'MAKE_USER_ADMIN',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowUiModal' },
              disable: !this.isOwner,
            },
          ],
        },
        stats: rightBarStats(statData),
        chartData: superAdminGraphChart(statData),
      }
      this.setRightbarContent(content)
    },

    tableActionList(status) {
      let actionList = [{ name: 'Edit' }, { name: 'TV_DEACTIVATE_USER' }]
      if (this.isOwner) actionList.push({ name: 'REVOKE_ACCESS' })
      let options = getOptionsBasedOnUserStatus(status, this.isManualPasswordResetAllowed)
      actionList = [...actionList, ...options]

      return actionList
    },
    getSuperAdmins(range) {
      this.currentAdmin = null
      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getSuperAdmin(payload)
        .then((res) => {
          this.loginUser = JSON.parse(localStorage.getItem('user'))
          this.adminList = res?.data?.records
          this.adminCounts = res?.data?.meta?.total_records
          this.showPagination = this.adminCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          this.adminList?.forEach((user) => {
            user.full_name = `${user.first_name} ${user.last_name}`
            user.actionsList = this.tableActionList(user.login_activated_at, user.id)
          })
          this.fetchSuperAdminStats({ $where: this.filtersData?.$where })
        })
        .finally(() => {
          this.noRecord = isEmpty(this.adminList)
          this.isLoading = false
        })
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('users', ['revokeUserEntityPermission']),
    ...mapActions('auth', ['resendUserSetupPassword']),
    ...mapActions('superadmin', ['getSuperAdmin', 'getSuperAdminStats', 'transferOwnership']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
