<template>
  <td :class="computedClasses">
    <slot />
  </td>
</template>

<script>
export default {
  props: {
    lowercase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      if (this.$attrs && this.$attrs.class) return this.$attrs.class
      else return `lg:px-6 px-3.5  ${!this.lowercase && 'capitalize'}`
    },
  },
}
</script>
