import { mapActions, mapState } from 'vuex'

/*
    Enable Filters In Dev Env .env.js
    1. First SEPARATE FILTER COMPONENT IF NOT SEPARATED
    2. In Filter Child Component add displayedColumns: [] key in filterspayload
       Add in title Wrapper
        :displayed-column-options="csvFilterOptions"
        :already-selected-displayed-column="filtersPayload.displayedColumns"
        @applyFilter="emitFiltersPayload($event, invalid)"
      Add These Props In Filters File
        props: {
          csvFilterOptions: {
            type: Array,
            default: () => [],
          },
          alreadySelectedDisplayedColumns: {
            type: Array,
            default: () => [],
          },
        },
      Add
        mounted() {
          if (this.alreadySelectedDisplayedColumns && this.alreadySelectedDisplayedColumns.length) {
            this.filtersPayload.displayedColumns = this.alreadySelectedDisplayedColumns
          }
        },

    3. Make TableHeadersArray as array of object & do its related tasks like skip skipForFilterOptions: true, skipForDataTableHead: true,
       Make Sure You Import File-Mixins file
       Add defaultSelectedColumns: [],
       Add alwaysDisplayColumnsForCSV:{}
    4. use function showColumnInDataTable in table head & bodies
    5. Add These Props & Events in FilterFileConponent
        :csv-filter-options="displayedFilterOptions"
        :already-selected-displayed-columns="filtersData.displayedColumns"
        @apply="applyFilters"
    6. Add Button in Rightbar to download csv i-e downloadCSV()
   */
export default {
  data() {
    return {
      displayedFilterOptions: [],
    }
  },
  created() {
    /* SETTING ALREADY SELECTED COLUMNS */
    this.init()
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
  },
  watch: {
    tableHeaders() {
      this.init()
    },
  },
  methods: {
    init() {
      if (this.defaultSelectedColumns && this.defaultSelectedColumns.length) {
        this.filtersData.displayedColumns = this.tableHeaders.filter((head) => {
          return this.defaultSelectedColumns.includes(head.dataTableName)
        })
      }
      this.displayedFilterOptions = this.tableHeaders?.filter((head) => !head.skipForFilterOptions)
    },
    downloadTemplte(fileName = 'template', headers = ['name', 'title']) {
      const blob = new Blob([headers], { type: 'text/csv', data: 'text/csv;charset=utf-8' })

      const url = window.URL.createObjectURL(blob)

      this.createAndDownloadFile(url, fileName)
    },

    createAndDownloadFile(url = '', fileName = '') {
      const a = document.createElement('a')

      a.setAttribute('href', url)

      a.setAttribute('download', `${fileName}.csv`)

      a.click()
    },

    convertCSVToArray(text, delimiter = ',') {
      const headers = text.slice(0, text.indexOf('\n')).split(delimiter)

      const rows = text.slice(text.indexOf('\n') + 1).split('\n')

      const convertedArray = rows.map((row) => {
        const values = row.split(delimiter)
        const item = headers.reduce((object, header, index) => {
          object[header] = values[index]
          return object
        }, {})
        return item
      })
      convertedArray.pop()
      return convertedArray
    },
    csvHeadersPayload(alwaysDisplayColumns, columnsData, extraColumnsHeaders = {}) {
      let returnObject = {}
      if (columnsData && columnsData.length) {
        const displayableColumns = {}
        columnsData?.forEach((row) => {
          displayableColumns[row.backendKeyForCSV] = row.readableValueForCSV
        })
        returnObject = { ...alwaysDisplayColumns, ...displayableColumns, ...extraColumnsHeaders }
      }
      return returnObject
    },

    async downloadCSV(action, extraAttributes) {
      if (this.isDataListEmpty) {
        this.$store.commit(
          'toast/NEW',
          { type: 'error', message: 'Can not download empty csv' },
          { root: true },
        )
        return
      }
      const csvObject = this.csvHeadersPayload(
        this.alwaysDisplayColumnsForCSV,
        this.filtersData?.displayedColumns,
      )
      const data = { csv_headers: csvObject, ...extraAttributes }
      let res, err
      switch (action) {
        case 'studentList': {
          ;[res, err] = await this.downloadStudentCSV(data)
          break
        }
        case 'receivableList': {
          ;[res, err] = await this.downloadReceivableCSV(data)
          break
        }
        case 'receivedList': {
          data.status = 'received'
          ;[res, err] = await this.downloadReceivedCSV(data)
          break
        }
        case 'receivedInstallementList': {
          ;[res, err] = await this.downloadReceivedInstallementCSV(data)
          break
        }
        case 'studentPayable': {
          ;[res, err] = await this.downloadReceivableCSV(data)
          break
        }
        case 'studentPaid': {
          data.status = 'received'
          ;[res, err] = await this.downloadReceivableCSV(data)
          break
        }
      }
      if (!err) {
        const csvHeader = 'data:text/csv;charset=utf-8,'
        const csvFileData = csvHeader + res?.data
        const encodedCsv = encodeURI(csvFileData)
        this.downloadFile(encodedCsv, `section-${this.currentSectionScope.title}-${action}.csv`)
      }
    },

    downloadFile(data, filename) {
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    ...mapActions('csv', [
      'downloadReceivableCSV',
      'downloadStudentCSV',
      'downloadReceivedCSV',
      'downloadReceivedInstallementCSV',
    ]),
  },
}
